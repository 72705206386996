.login-box {
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  animation: fadeIn 0.15s ease-in;
  backdrop-filter: blur(20px);
  background-color: rgba(0, 0, 0, 0.65);
}
.login-screen {
  margin-top: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  .container {
    color: #ebebeb;
    position: relative;
    background-color: rgba(69, 91, 217, 0.6);
    height: fit-content;
    width: min(500px, 95%);
    border-radius: 10px;
    .close-btn {
      position: absolute;
      right: 2%;
      top: 2%;
      background-color: transparent;
      border: none;
      transform: scale(0.75);
    }

    h2 {
      margin: 10%;
      text-align: center;
      font-size: 26px;
    }
    h2::after {
      content: "";
      display: block;
      width: 70%;
      border-bottom: solid 1px rgb(255, 255, 255);
      margin: 0 auto;
      padding-bottom: 5%;
    }

    .text-section {
      margin: 0 auto;
      // max-width: 75%;
      max-width: max(75%, 280px);

      .PhoneInput {
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: 128px;

        .PhoneInputInput {
          height: 43px;
          background-color: #677adf;
          color: #ccd1f4;
          z-index: 10;
          border-radius: 10px;
          border-bottom: none;
          &:focus {
            border: none;
            outline: 2px solid #f4cd46;
          }
        }
        .PhoneInputCountrySelect {
          height: 43px;
          border-radius: 10px;
          position: relative;
          background-color: #677adf;
          color: #ccd1f4;
          width: 80%;
          border-radius: 10px;
          border-bottom: none;
          &:focus {
            border: none;
            outline: 2px solid #f4cd46;
          }
        }
        .PhoneInputCountryIconImg {
          position: absolute;
          right: 0;
          top: -38px;
          width: 47px;
          height: 32px;
          border-radius: 10px;
          border: #ffffffc9 2px solid;
        }
      }
      form {
        padding: 50px 0 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;

        .kep-login-facebook {
          border-radius: 50px;
          font-size: inherit;
          font-family: "Nunito", sans-serif;
          padding: 10px 20px;
          margin-bottom: 30px;
          background-color: #0d6efd;
          text-transform: none;
          font-weight: 600;
        }

        .register-input-wrapper {
          position: relative;
          margin-bottom: 20px;

          span,
          img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
          }

          span {
            left: 15px;
            font-size: 15px;
            color: #ccd1f4;
            transition: transform 0.2s, font-size 0.2s;
            pointer-events: none;
          }

          input {
            margin: 0;

            &:focus {
              border: none;
              box-shadow: 0px 0px 5px #ffffff;
              + span {
                transform: translate(-8px, -20px);
                font-size: 10px;
              }
            }
          }

          .active-input {
            transform: translate(-8px, -20px);
            font-size: 10px;
          }
        }
        .sign-up-inputs {
          color: #ffffffc9;
          background-color: #677adf;
          padding: 5px;
          padding-left: 10px;
          border: none;
          border-radius: 10px;
          width: 100%;
          height: 43px;
          &:focus {
            outline: none;
          }
          &::placeholder {
            font-size: 14px;
            font-weight: 600;
            padding-left: 10px;
            color: #ffffffc9;
          }
        }
        .recaptcha {
          position: unset;
          width: unset;
          height: 80px;
          display: block;
        }
        .terms-of-use-container {
          width: 100%;
          display: flex;
          gap: 10px;
          margin-bottom: 10px;
          align-items: center;
          p {
            font-size: 15px;
            margin: 0;
          }
          #terms-of-use {
            max-width: 25px;
            height: 25px;
          }
        }
        div {
          position: relative;
          width: 100%;
          height: 43px;
          .input-img {
            position: absolute;
            width: 24px;
            right: 3%;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        img {
          width: 20px;
        }
        .other-login {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: space-around;
          align-items: center;
          .facebook {
            // cursor: pointer;
            // border-radius: 10px;
            // background-color: #677adf;
            // width: 50%;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // gap: 8px;
            // padding: 5px;
            img {
              width: 30px;
            }
            p {
              text-align: center;
              margin: 0;
              font-size: 14px;
              width: 60%;
            }
          }
        }
        p {
          margin: 0 15px 15px 15px;
          text-align: center;

          a {
            color: #f4cd46;
            text-decoration: none;
          }
        }
        .login-btns-wrapper {
          height: auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }
        .login-btn {
          height: 46px;
          display: block;
          background-color: #f4cd46;
          padding: 10px 28px;
          font-weight: bold;
          border: none;
          border-radius: 50px;
          margin-bottom: 20px;
          color: #343434;
          margin-right: 5px;
        }
        .multiple-inputs-wrapper {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          .register-input-wrapper {
            width: 47%;
          }
        }
      }
      .forgot-password {
        text-decoration: none;
        color: #ebebeb;
        margin-bottom: 10px;
      }
      .remember-me {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 0;
        span {
          text-wrap: nowrap;
        }
        .switcher-field-custom-wrapper {
          height: fit-content;
          .switch-input:checked + label {
            background-color: #08336e;
          }
        }
      }
      .login-remember-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-bottom: 10px;

        p,
        a {
          margin: 0;
          text-align: center;
        }

        a {
          color: #f4cd46;
        }
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

//Verification page edit
.verification {
  margin: 10% auto;
  display: flex;
  gap: 40px;
  justify-content: center;
  align-items: center;
  text-align: center;
  strong {
    padding-top: 30px;
    margin-left: 20px;
    letter-spacing: 20px;
  }
  @media only screen and (max-width: 670px) {
    flex-direction: column;
  }
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
