.newsletter-profile-container {
  h6 {
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    line-height: 23px;
  }

  .newsletter-application {
    padding: 10px 25px;
  }
}
