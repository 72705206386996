.error-page {
  img {
    margin: 0 auto;
    width: 15%;
    display: block;
    margin-top: 40px;
  }

  h5 {
    color: #455cd9;
    text-align: center;
    margin-top: 30px;
  }
}
