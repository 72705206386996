.custom-navbar {
  width: 100%;
  background: linear-gradient(120deg, #003c7d 0%, #2793a7 100%);

  padding: 5px 30px;
  color: #fff;
  z-index: 999;

  @media only screen and (max-width: 1199px) {
    padding: 3px 10px;
  }
  .hamburger {
    display: none;
    @media only screen and (max-width: 1199px) {
      display: block;
    }
  }
  .navbar-brand {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    p {
      color: white;
      margin-bottom: 0;
    }
  }
  img {
    width: 35px;
  }

  .navbar-nav {
    width: 100%;
    padding: 5px 0;

    .nav-link {
      transition: all 0.3s;
      color: #fff;
      font-size: 0.9rem;
      font-weight: 500;
      padding: 3px 10px;
      border-radius: 5px;
      &:hover {
        background: rgba(255, 255, 255, 0.11);
        transform: scale(1.05);
      }
    }

    .navbar-middle-part {
      display: flex;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      @media only screen and (max-width: 1199px) {
        flex-direction: column;
        gap: 5px;
      }
    }
  }
}
.nav-search-wrapper {
  margin-left: auto;
  position: relative;
  flex: 1;
  padding: 0 10px;
  max-width: 400px;
  form {
    position: relative;
  }

  input {
    background-color: #fff;
    border: none;
    outline: none;
    color: #455cd9;
    padding: 5px 20px 5px 10px;
    border-radius: 10px;
    font-size: 14px;
    width: 100%;
    box-shadow: 0px 0px 2px rgba(48, 0, 207, 0.25);

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #455cd9;
    }
  }

  img {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 17px;
  }

  .loader {
    width: 15px;
    height: 15px;
    border: 2px solid #576cdc;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    position: absolute;
    right: 3px;
    top: 8px;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.search-nav-right {
  li {
    width: 95%;
    margin-top: auto;
    .nav-search-wrapper {
      max-width: 100%;
    }
  }
}
.navbar-auth {
  display: flex;
  gap: 15px;
  font-size: 0.9rem;
  @media only screen and (max-width: 1199px) {
    padding-top: 5px;
    gap: 15px;
    flex-direction: column;
    margin: 0 auto;
  }
  .navbar-link {
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);
    border-radius: 0.3rem;
    padding: 3px 8px;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      background: rgba(255, 255, 255, 0.11);
    }
  }
}
