// Sidebar.scss
.sidebar {
  height: 100vh;
  background: linear-gradient(to bottom, #999, #5a5a5a);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  overflow: hidden;
  transition: width 0.3s ease-in-out;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100000;

  .button-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    gap: 1.5rem;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }

  .toggle-btn,
  .close-btn {
    background: none;
    border: none;
    cursor: pointer;
  }
  .user-info-wrapper {
    display: flex;
    font-size: 0.9rem;
    width: 100%;
    gap: 1rem;
    padding: 10px;
    margin-bottom: 1rem;

    flex-wrap: wrap;
    border-top: 1px solid #ffffff40;
    border-bottom: 1px solid #ffffff40;
  }

  .nav-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    overflow: auto;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #ffffff40;
  }

  .nav-item {
    display: flex;
    gap: 12px;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
    &.active {
      background: rgba(255, 255, 255, 0.1);
    }
  }
}

.open-btn {
  position: fixed;
  left: 2px;
  z-index: 10000;
  top: 50%;
  transform: translateY(-50%);
  background: #8b8b8b;
  color: white;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0 3px 5px;
  border-radius: 5px;
}
.screen-title-name {
  font-size: 1.4rem;
  font-weight: 600;
  color: #777;
  padding: 20px 0 20px 4%;
  margin-bottom: 20px;
  background: linear-gradient(to bottom, rgba(242, 242, 242, 0.9) 0%, rgba(255, 255, 255, 0) 100%);
  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.profile {
  position: relative;
  .profile-form {
    margin: 1%;
    padding: 2%;
    min-height: 500px;
    background-color: #ffffff;
    border-radius: 0.7rem;
    box-shadow: 0 0 10px rgba(115, 115, 115, 0.15);

    .role-selector {
      width: fit-content;
      outline: none;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    .smaller-profile {
      width: 100%;
      max-width: 800px;
      .modal-window {
        height: 180px;
        width: min(350px, 90%);
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 9999;
        transform: translate(-50%, -50%);
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        padding: 20px 10px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;

        h6 {
          text-align: center;
          font-size: 1.1rem;
        }
      }
    }
    .form {
      height: 100%;

      .PhoneInput {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        flex-wrap: wrap;
        width: min-content;

        .PhoneInputCountryIconImg {
          display: none;
        }
      }

      .preset-category {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        gap: 20px;
        width: 96%;
        p {
          margin-bottom: 0;
          color: #455cd9;
          font-weight: 600;
          width: 100%;
          text-align: center;
        }
      }
    }

    .organize-event-plan-wrapper {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;

      svg {
        max-width: 450px;
        max-height: 450px;
        height: auto;
      }
    }

    .organize-form {
      .label-wrapper {
        display: flex;
        width: 98%;
        margin-bottom: 5px;
        .input-label-custom {
          flex: 1;
        }
      }

      .modal {
        position: fixed;
        z-index: 9999;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #e7e7e7c4;
        border-radius: 10px;
        display: flex;
        gap: 10px;
        flex-direction: column;
        max-width: 300px;
        height: auto;
        padding: 15px;
        div {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        h6 {
          text-align: center;
          margin: 0;
        }
        label {
          width: 135px;
        }

        input {
          text-align: center;
          display: block;
          background: #ffffff;
        }
        .disable-zone-checkbox {
          height: 20px;
          width: 20px;
          margin-right: auto;
        }
        button {
          margin: 0 auto;
        }
        .disable-zone {
          display: flex;
          align-items: center;
          justify-content: center;

          input {
            width: 13px;
            margin: 0 0 0 10px;
          }
        }

        p {
          margin-bottom: 15px;
        }
      }

      .event-zones-container {
        display: flex;
        gap: 5px;
        margin-bottom: 8px;
        .input-field-custom {
          width: 100%;
        }
        .trashcan {
          cursor: pointer;
          margin-top: 5px;
        }
      }

      .row {
        padding-bottom: 20px;
      }
    }
    .add-reseller-container {
      max-width: 800px;
      margin: 0 auto;
      .row {
        justify-content: unset;
        gap: 0;

        #info-icon {
          width: 45px;
          margin-left: auto;
        }
        .link-icon {
          width: 20px;
          padding: 0;
          display: block;
          margin-right: 13px;

          img {
            width: 100%;
          }
        }

        .col-lg-6 {
          width: 50%;
          display: flex;
          gap: 1rem;
          flex-direction: column;

          input {
            width: 90%;
          }

          div {
            display: flex;
            align-items: center;
          }
        }
        .col-lg-6:nth-child(2) {
          input {
            margin-left: auto;
          }
        }

        h6 {
          color: #455cd9;
          font-size: 16px;
          font-weight: 600;
        }
      }
      form {
        button {
          margin: 30px auto 20px auto;
          display: block;

          font-size: 14px;
        }
      }

      .control-acc-wrapper {
        width: 96.5%;
        height: 60px;
        border-radius: 7px;
        margin: 0 auto 10px auto;
        display: flex;
        align-items: center;
        padding: 5px 10px;
        .performer {
          padding: 0 4px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &:nth-child(odd) {
          background-color: rgba(69, 91, 217, 0.15);

          img {
            background-color: rgba(255, 255, 255, 0.9);
          }

          .control-line {
            background-color: #fff;
          }
        }
        &:nth-child(even) {
          background-color: #fff;

          img {
            background-color: rgba(69, 91, 217, 0.153);
          }

          .control-line {
            background-color: rgba(69, 91, 217, 0.303);
          }
        }

        img {
          width: 30px;
          height: 30px;
          cursor: pointer;
          border-radius: 50%;
          padding: 7px;
          margin-left: auto;

          &:hover {
            transform: scale(1.05);
          }
        }
        img:nth-child(6) {
          margin-right: 5px;
        }

        p {
          margin: 0;
          font-weight: 600;
          text-align: center;
        }

        p:nth-child(1) {
          width: 30%;
        }
        p:nth-child(5) {
          width: 20%;
        }
        p:nth-child(3) {
          width: 50%;
        }

        .control-line {
          height: 90%;
          width: 1px;
        }
      }

      .control-acc-row {
        margin-bottom: 20px;
      }
    }
  }

  .w-7rem {
    width: 7rem;
  }
  .w-10rem {
    width: 10rem;
  }
  button {
    border: none;
    outline: none;

    border-radius: 0.3rem;
    height: 2rem;
    box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.1);
    padding: 0.5rem 1rem;
    background: #007aff;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 600;
  }
}
#tooltip {
  background-color: #455cd9;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  z-index: 9999;
  transition: none;
  position: absolute;
  display: none;

  p {
    &:last-child {
      margin: 0;
    }
  }
}

.profile-btns-row {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem 0;
}

.update-profile-form-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5rem;
}

.general-info-form {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.separator {
  width: 100%;
  border-bottom: #fff 1px dashed;
  margin: 1rem 0;
}

.input-field-custom-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1px;
  position: relative;
}
.table-field-ref {
  position: relative;
  svg {
    color: #888;
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
  }
}
.pointer {
  cursor: pointer;
}
.input-label-custom {
  color: #888;
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 0.4rem;
  text-wrap: nowrap;
}
.input-field-custom,
input,
.PhoneInputInput {
  color: #777;
  font-size: 0.9rem;
  min-width: 100px;
  font-weight: 600;
  height: 2rem;
  border: none;
  padding: 0 0.4rem;
  border-radius: 0.2rem;
  border-bottom: #ddd 1px solid;
  &:disabled {
    color: #8c8c8c;
    outline: #ffffff 1px solid;
  }
  &:focus {
    // box-shadow: 0px 6px 10px -9px #007aff;
    border-bottom: rgb(162, 204, 240) 1px solid;
  }
}
.input-field-error {
  color: rgb(166, 12, 12);
  // box-shadow: 0px 5px 8px -5px rgba(255, 0, 0, 0.53);
  border-bottom: rgba(255, 0, 0, 0.4) 1px solid;
}
.input-field-error-message {
  font-weight: 500;
  padding: 0 0.3rem 0.3rem;
  color: rgb(166, 12, 12);
  font-size: 0.7rem;
}

.select-field-custom-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1px;
  width: fit-content;
}

.select-custom,
.PhoneInputCountrySelect,
select {
  color: #777;
  font-size: 0.9rem;
  font-weight: 600;
  height: 2rem;
  border-radius: 0.2rem;
  padding: 0 0.7rem;
  border: none;
  outline: none;
  border-bottom: #eee 1px solid;
  &:focus {
    outline: none;
  }
}

.switcher-field-custom-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.6rem;
  width: fit-content;
}
.flex-gap-05rem {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}
.sponsors-list {
  display: flex;
  font-size: 0.9rem;
}

.sponsors-container {
  display: flex;
  flex-direction: column;
}

.center-btn {
  width: fit-content;
  margin: 0 auto;
}
.flex-end {
  align-self: flex-end;
}
.profile .info-container {
  text-align: center;
  position: fixed;
  z-index: 9999;
  padding: 1rem 2rem;
  left: 50%;
  top: min(10%, 5vh);
  width: 17rem;
  transform: translate(-50%);
  border-radius: 0.2rem;
  overflow: hidden;
  background-color: #ffffff83;
  backdrop-filter: blur(5px);
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.05);
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.align-self-end {
  align-self: flex-end;
}

.mb-1rem {
  margin-bottom: 1rem;
}

.profile-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.6rem;
  border: none;
  outline: none;
  padding: 0.35rem 1rem;
  border-radius: 0.2rem;
  background-color: #007aff;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.9rem;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 2px 0.5px #007aff;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }
  &:hover {
    background-color: #007bffb1;
  }
  &:active {
    animation: pulse 0.5s ease-in-out;
  }
  .loading-spinner {
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes pulse {
    0% {
      scale: 0.9;
    }

    100% {
      scale: 0.95;
    }
  }
}

.secondary-btn {
  background: transparent !important;
  color: #777 !important;
  &:hover {
    background: #f2f2f2 !important;
  }
  box-shadow: 0 0 4px #bbb !important;
}

.combo-box {
  position: relative;
  flex: 1;
}
.all-cities-wrapper {
  position: absolute;
  top: 3.4rem;
  width: 100%;
  background-color: #fff;
  overflow: auto;
  border-radius: 0.2rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 9999;
  max-height: 150px;
  height: fit-content;
}

.city-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 0.2rem;
  background-color: #737373ba;

  span {
    color: #fff;
    width: 100%;
    height: fit-content;
    padding: 5px 10px;
    text-align: center;
    font-size: 0.9rem;
    &:hover {
      cursor: pointer;
      background-color: #007aff;
    }
  }
}
.add-zone {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: fit-content;
  margin: 0.5rem auto;
  cursor: pointer;
  color: #ffffff;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 0.2rem;
  background-color: #007aff;
  &:hover {
    background-color: #007bff86;
  }
}
button:hover {
  background-color: #dfbf4d;
}

.image-upload-container {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
  div {
    min-width: 46%;
    h3 {
      text-align: center;
      font-size: 14px;
      font-weight: 600;

      span {
        color: #455cd9;
      }
    }
  }

  .image-wrapper {
    background-color: #fff;

    border-radius: 0.5rem;
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin-top: 10px;
    border: 1px dashed #ccc; /* Optional: visualize the wrapper */

    img {
      width: 50px;
      height: auto;
      cursor: pointer;
      object-fit: contain;
    }

    .uploaded-event-image {
      width: 100%;
      height: 90%;
      border-radius: 0.2rem;
    }
  }
}
