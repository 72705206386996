.mytickets-container {
  margin: 0 auto;
  .button-wrapper-my-events {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .myevent-card-reseller {
    height: 300px !important;
    user-select: none;
  }
  .myevent-card,
  .myevent-card-reseller {
    width: 100%;
    position: relative;
    border-radius: 7px;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
    transition: margin 0.4s ease-out;
    display: flex;
    align-items: center;

    .myevent-card-part-1 {
      display: flex;
      height: 100%;
      width: min(27%, 300px);
      background-color: #b8bede;
      border-radius: 7px;
      flex-direction: column-reverse;

      .conc-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 3% 2%;
        text-align: center;
        gap: 0.2rem;
        flex-grow: 1;
        @media only screen and (max-width: 450px) {
          padding: 2px;
        }

        p {
          font-size: 0.8rem;
          font-weight: 500;
          margin-bottom: 0;
        }
      }
      img {
        min-height: 120px;
        object-fit: cover;
      }
    }
    .sales-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      flex: 1;
      overflow: hidden;
    }
    .myevent-card-part-2 {
      height: 100%;
      display: flex;
      flex-direction: column;

      &:nth-child(2) {
        background-color: #fff;
      }

      .heading {
        color: #455cd9;
        font-weight: 600;
        text-align: center;
        margin-top: 2px;
        margin-bottom: 2px;
      }
      .top-part {
        font-size: medium;
        width: 96%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: #b8bede 2px solid;
        @media only screen and (max-width: 600px) {
          span {
            text-align: center;
            font-size: smaller;
          }
        }
      }
      .bottom-part-wrapper {
        height: 75%;
        overflow-x: auto;

        .bottom-part {
          margin: 0 auto;
          display: flex;
          height: 100%;
          align-items: center;
          font-size: 14px;
          width: fit-content;
          @media only screen and (max-width: 400px) {
            font-size: 12px;
          }

          .category-card {
            min-width: 180px;
            text-align: center;
            text-wrap: nowrap;

            display: flex;
            border-right: #b8bede 2px solid;
            padding: 0 12px;
            flex-direction: column;
            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }
    .myevent-card-part-3 {
      min-width: min(40px, 10%);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 7px;
      cursor: pointer;
      img {
        cursor: pointer;
        width: 20px;
      }
    }

    .myevents-card-dropdown {
      width: 100%;
      background-color: rgba(69, 91, 217, 0.105);
      position: absolute;
      top: 300px;
      left: 0;
      transition: max-height 0.4s ease-out;
      overflow: hidden;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;

      .profile-concert-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
      }
      .warnning-message {
        display: block;
        width: 100%;
        text-align: center;
        margin: 13px 0 3px;
      }

      .heading {
        margin: 15px 20px;
        color: #455cd9;
        font-weight: 600;
      }

      .resellers-card {
        box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.15);
        margin-bottom: 10px;
        padding: 10px 5px;
        .resellers-card-top-row {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 1rem;
          div {
            padding: 1%;
            p,
            h6 {
              font-size: 13px;
              margin: 0;
              text-align: center;
              @media only screen and (max-width: 500px) {
                font-size: 11px;
              }
            }

            h6 {
              font-weight: 600;
              margin-bottom: 10px;
              font-size: 14px;
            }
          }
          .resellers-totals {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 10px;
            align-self: center;
            gap: 0.5rem;
          }

          .status-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 0.5rem;
            width: fit-content;
            padding: 0.2rem 1rem;
          }

          .resellers-info {
            flex: 1;
            background-color: #fff;
            border-radius: 0.5rem;
            min-width: 250px;
            display: flex;
            gap: 10px;
            justify-content: space-evenly;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            height: auto;
            text-align: center;
            padding: 10px;
            .zone-card {
              padding: 10px 15px;
              border: 1px dashed #ccc;
              border-radius: 0.6rem;
              display: flex;
              flex-direction: column;
            }
          }
        }

        .resellers-card-last-row {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.6rem;

          p {
            margin: 0 10px 0 auto;
            font-size: 13px;
            font-weight: 600;
            margin-top: 10px;

            @media only screen and (max-width: 500px) {
              font-size: 11px;
            }
          }

          .add-payment-wrapper {
            display: flex;
            margin: 10px 0;
            width: 100%;
            gap: 0.5rem;
            flex-wrap: wrap;
            input {
              flex: 1;
              text-align: center;
              &:disabled {
                background-color: #fff;
              }
            }
          }
        }
        .plus-icon {
          display: block;
          width: 25px;
          cursor: pointer;
        }
      }
      .selling-timestamp {
        padding: 0 20px;

        .time-input-wrapper {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          gap: 0.3rem;
          flex-wrap: wrap;

          .print-pdf-btn {
            text-decoration: none;
            align-self: flex-end;
          }
        }
        border-bottom: 2px dotted #ffffff;
      }
      .selling-overview-wrapper {
        display: flex;
        background-color: #fff;
        border-radius: 10px;
        width: 95%;
        margin: 0 auto 20px;
        padding: 20px 5px;

        .date {
          margin: 0;
          background-color: rgba(124, 140, 228, 0.356);
          width: fit-content;
          padding: 5px 15px;
          border-radius: 10px;
          font-size: 14px;
          opacity: 0.75;
          font-weight: 600;
        }

        .heading {
          margin: 0 0 10px 0;
          text-align: center;
        }

        div:nth-child(1) {
          width: 20%;
        }
        .selling-overview-date {
          display: flex;
          align-items: center;
        }
        div:nth-child(2) {
          width: 50%;

          div {
            width: 100%;
            background-color: #ebebee;
            display: flex;
            padding: 5px;
            border-radius: 10px;
            overflow-x: auto;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }

            div {
              width: 33.33%;
              flex-direction: column;
              border-right: 2px solid rgba(69, 91, 217, 0.501);
              border-radius: 0;
              margin: 0;

              &:last-child {
                border-right: none;
              }

              p {
                margin: 0;
                font-size: 13px;
                font-weight: 600;
                text-align: center;
              }
            }
          }
        }
        div:nth-child(3) {
          width: 30%;
        }
        .sold-tickets-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;

          div {
            width: 100%;
            margin-top: 40px;
          }

          p {
            text-align: center;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
      .provision-summary {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 0.5rem;
        margin: 10px 20px;
        input {
          width: 140px !important;
          text-align: center;
        }
      }
    }
  }
}
.resellers-card-bottom-row-wrapper {
  margin-top: 10px;
  overflow-x: scroll;
  .resellers-card-bottom-row-container {
    position: relative;
    padding-top: 20px;
    min-width: fit-content;
    .label-track {
      position: absolute;
      text-align: center;
      width: 96.8%;
      display: flex;
      margin-left: 5px;
      top: 0px;
      span {
        flex: 1;
      }
    }
    .resellers-card-bottom-row {
      display: flex;
      background-color: #fff;
      border-radius: 0.2rem;
      padding: 0 3px;
      height: 30px;
      gap: 5px;
      align-items: center;
      justify-content: space-between;
      div {
        font-size: 0.85rem;
        font-weight: 600;
        flex: 1;
        text-align: center;
        padding: 0 8px;
        border-right: 1px solid #ddd;
      }

      .approve-transaction-button {
        height: 100%;
        font-size: 0.6rem;
      }

      .check-icon {
        margin: 0 0.5rem;
        color: #47c425;
      }
      .x-icon {
        margin: 0 0.5rem;
        color: red;
      }
    }
  }
}
