.list-page-container {
  width: 100%;
  min-height: 110vh;
  padding: 30px 0;
  position: relative;
  /* CSS file or inline styles within the component */
  .fade-in {
    opacity: 0; /* Set initial opacity to 0 (invisible) */
    transition: opacity 0.5s ease-in-out; /* Add a smooth transition for the opacity property */
  }

  .fade-in.visible {
    opacity: 1; /* When the "visible" class is added, set opacity to 1 (visible) */
  }
  .search-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    backdrop-filter: blur(12.5px);
    background: rgba(6, 6, 6, 0.1);
  }

  .no-search-results {
    text-align: center;
    margin-top: 40px;
    color: #455cd9;
    font-weight: 600;
  }

  .list-page-search-wrapper {
    max-width: 488px;
    margin: 0 auto 30px auto;
    position: relative;

    img {
      z-index: 6;
      position: absolute;
      width: 20px;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }

    .mini-loader {
      z-index: 6;
      position: absolute;
      width: 20px;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }

    .list-page-search {
      width: 100%;
      display: block;
      height: 40px;
      border-radius: 20px;
      border: none;
      text-align: center;
      outline: none;
      box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
      position: relative;
      z-index: 1;
      padding-right: 33px;
      color: rgba(52, 52, 52, 0.808);

      &:focus {
        outline: none;
      }

      &::placeholder {
        font-weight: 300;
        color: #646464;
      }
    }
  }

  .list-page-card {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 300px;
    flex-grow: 1;
    @media only screen and (max-width: 800px) {
      height: 300px;
    }
    @media only screen and (max-width: 600px) {
      height: 250px;
    }
    @media only screen and (max-width: 450px) {
      height: fit-content;
      margin-bottom: 15px;
    }

    .list-page-landscape,
    .list-page-overlay {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -6;
    }
  }
  .list-page-type-wrapper {
    width: min(80%, 1200px);
    height: 85%;
    margin: 0 2%;
    box-shadow: 0px 0px 10px 3px rgba(65, 65, 65, 0.1);
    z-index: 2;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 600px) {
      height: 80%;
    }
    @media only screen and (max-width: 450px) {
      flex-direction: column;
      justify-content: space-between;
    }
    h5 {
      font-size: 33px;
      @media only screen and (max-width: 600px) {
        font-size: 20px;
        text-align: center;
        margin-bottom: 0;
      }
      @media only screen and (max-width: 450px) {
        width: 100%;
        font-size: 20px;
      }
    }
    .media-helper {
      width: 100%;
      @media only screen and (max-width: 800px) {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
      }
    }
    .first-child {
      flex-grow: 1;
      text-align: center;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 1%;
      @media only screen and (max-width: 450px) {
        flex-direction: row;
        width: 100%;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }

      .list-page-date {
        background-color: rgba(255, 255, 255, 0.457);
        padding: 5px;
        border-radius: 7px;
        color: #343434;
        font-weight: 600;

        font-size: 17px;
        @media only screen and (max-width: 600px) {
          font-size: 13px;
          margin: 5px;
          padding: 5px;
        }
        @media only screen and (max-width: 450px) {
          font-size: 12px;
        }

        &::first-letter {
          text-transform: uppercase;
        }
      }

      .list-page-line {
        height: 1px;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.802);
        @media only screen and (max-width: 450px) {
          display: none;
        }
      }

      .list-page-desc {
        font-size: 16px;
        overflow: auto; /* Hide any overflowing content */
        margin: 12px 0 0 0; /* Remove any default margins that may interfere with height calculation */
        display: -webkit-box; /* For Safari, iOS, and older versions of Android */
        -webkit-box-orient: vertical; /* Set the layout mode to vertical */

        @media only screen and (max-width: 800px) {
          display: none;
          font-size: 14px;
        }
      }
    }
    .media-helper-2nd {
      display: flex;
      @media only screen and (max-width: 450px) {
        display: flex;
      }
    }
    .second-child {
      display: flex;
      .list-page-landscape2 {
        height: 100%;
        object-fit: cover;
        margin-left: auto;
        @media only screen and (max-width: 800px) {
          width: 100%;
        }
        @media only screen and (max-width: 450px) {
          width: 100%;
          margin: 0 0 auto 0;
          border-radius: 0 0 0 10px;
        }
      }
    }
    .third-child {
      width: max(75px, 6%);
      display: flex;

      .list-page-btns-wrapper {
        height: 100%;
        width: 100%;
        margin-left: auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        background-color: rgba(244, 206, 70, 0.859);
        border-radius: 0 10px 10px 0;
        @media only screen and (max-width: 450px) {
          border-radius: 0 0 10px 0;
        }

        a {
          height: 49.75%;
          width: 75px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #414141;
          font-weight: 600;
          font-size: 17px;
          text-decoration: none;
          transition: background-color 0.2s, font-size 0.2s;
          border-top-right-radius: 15px;
          @media only screen and (max-width: 450px) {
            width: 100%;
          }
          &:hover {
            background-color: #dfc466;
            font-size: 18px;
          }
        }
        a:nth-child(3) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 15px;
        }

        .list-page-btns-line {
          height: 0.5%;
          width: 80%;
          background-color: #fff;
          margin: 0 auto;
        }
      }
    }
  }
  .card-transition {
    width: 100%;
    height: 30px;
    margin: -10px 0;
    background: rgb(217, 217, 217);
    background: linear-gradient(
      180deg,
      rgba(217, 217, 217, 0) 0%,
      #dfdfdf 30%,
      #e2e2e2 60%,
      rgba(217, 217, 217, 0) 100%
    );
    position: relative;
    z-index: 3;
  }
}
