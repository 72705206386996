.info-page {
  max-width: 900px;
  margin: 0 auto;
  padding: 40px 0;

  .navbar-terms-of-use {
    display: flex;
    margin: 0 auto 7%;
    width: 95%;
    justify-content: space-evenly;
    a {
      padding: 7px 15px;
      text-decoration: none;
      border-radius: 10px;
    }
    .current {
      background-color: #ffffff;
      box-shadow: 0px 25px 15px -20px rgb(7, 29, 113);
    }
  }

  h4 {
    text-align: center;
    color: #455cd9;
    font-weight: 600;
    margin-bottom: 40px;
  }
  .terms-h4 {
    margin-bottom: 0;
  }

  .page-row {
    padding: 30px;
    border-radius: 10px;
    margin: 0 auto 10px;
    width: 95%;
    font-size: 0.8rem;
    box-shadow: 5px 5px 90px 20px rgba(0, 0, 0, 0.05);
    @media only screen and (max-width: 470px) {
      padding: 10px;
    }

    &:nth-child(even) {
      background-color: rgba(255, 255, 255, 0.5);
    }
    &:nth-child(odd) {
      background-color: rgba(255, 255, 255, 0.2);
    }

    .search-card-container {
      height: 120px;
      display: flex;
      align-items: center;
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
      padding-right: 15px;
      border-radius: 10px;
      margin-bottom: 10px;

      max-width: 100%;
      @media only screen and (max-width: 670px) {
      }

      @media only screen and (max-width: 470px) {
      }

      &:nth-child(even) {
        background: #f3f3f3;
      }

      img {
        height: 100%;
        object-fit: cover;
        margin-right: 5px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

        @media only screen and (max-width: 470px) {
          height: none;
          width: 50%;
        }
      }
      h3 {
        font-size: calc(0.9rem + 0.6vw);
      }
      p {
        color: rgba(52, 52, 52, 0.75);
        font-weight: 600;
        font-size: 15px;
        text-transform: capitalize;
        margin-top: 0;

        @media only screen and (max-width: 470px) {
          margin: 0;
        }

        &::first-letter {
          text-transform: uppercase;
        }
      }
      .search-card-content-wrapper {
        display: flex;
        align-items: center;
        width: 100%;

        @media only screen and (max-width: 670px) {
          flex-direction: column;
          width: 50%;
          @media only screen and (max-width: 370px) {
            width: 50%;
          }
          .card-btns-wrapper {
            margin: 0;
            flex-direction: row;
            height: auto;

            a {
              height: fit-content;
              padding: 3px 6px;
              width: 106px;
            }

            a:nth-child(1) {
              margin-right: 5px;
            }
            @media only screen and (max-width: 470px) {
              a:nth-child(1) {
                margin: 0 0 5px 0;
              }
            }
          }

          .card-info-wrapper {
            max-width: 90%;
            h3,
            p {
              text-align: center;
              margin-bottom: 0;
              @media only screen and (max-width: 470px) {
                transform: scale(0.88);
              }
              @media only screen and (max-width: 370px) {
                padding: 0;
                line-height: 15px;
                p {
                  font-size: 12px;
                }
              }
            }
            h3 {
              @media only screen and (max-width: 470px) {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
    .search-cards-container {
      margin-top: 20px;
      height: 500px;
      overflow-y: auto;
      overflow-x: hidden;
      padding-top: 5px;

      .card-info-wrapper {
        margin: 0 10px 0 20px;
        @media only screen and (max-width: 470px) {
          margin: 0;
        }

        h3 {
          font-size: 23px;
          font-weight: 600;
          @media only screen and (max-width: 470px) {
            margin: 0;
          }
        }
      }
    }
    .card-btns-wrapper {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      height: 100px;
      width: fit-content;
      justify-content: space-evenly;

      a {
        text-decoration: none;
        position: relative;
        opacity: 1;
        top: 0;
        transform: translateY(0);
        width: 150px;
        font-weight: 600;
        font-size: 15px;
      }

      @media only screen and (max-width: 470px) {
        gap: 3px;
        flex-direction: row;
        width: 80%;
        a {
          transform: scale(0.8);
          margin: 0;
          width: 80px;
        }
      }
    }
  }
  .card-btns-wrapper {
    width: 100%;

    a {
      background-color: #f4cd46;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      padding: 6px 32px;
      width: fit-content;
      color: #343434;
      border-radius: 50px;
      text-decoration: none;
      font-size: 16px;
      font-weight: 600;
      opacity: 0;
      transition: opacity 0.2s, background-color 0.2s;

      &:hover {
        background-color: #dbb83f;
      }
    }
  }
}

.newsletter-application {
  text-decoration: none;
  margin-top: 20px;
  display: block;
  background-color: #c6a630;
  border: none;
  width: fit-content;
  color: #343434;
  padding: 5px 25px;
  border-radius: 50px;
  font-weight: 600;
}
