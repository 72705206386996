.mytickets-card {
  height: 200px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  transition: margin 0.4s ease-out;
  border-radius: 10px;

  @media screen and (max-width: 600px) {
    height: 220px;
  }
  img {
    height: 100%;
    object-fit: cover;
    border-radius: 7px 0px 0px 7px;
  }

  .mytickets-card-part-arrow {
    width: 8%;
    height: 100%;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 7px 0 0;
    cursor: pointer;

    img {
      width: 100%;
      max-width: 30px;
      height: auto;
      cursor: pointer;
    }
  }

  .mytickets-card-part {
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    flex: 1;
    flex-wrap: wrap;
  }
  .mytickets-card-dropdown {
    width: 100%;
    background-color: rgba(82, 104, 231, 0.1);
    box-shadow: 0 3px 10px -3px rgba(129, 109, 254, 0.2);
    position: absolute;
    top: 200px;
    left: 0;
    transition: max-height 0.4s ease-out;
    overflow: hidden;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    @media screen and (max-width: 600px) {
      top: 220px;
    }

    .profile-concert-wrapper {
      padding: 10px 5px;

      .ticket-card {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        gap: 0.8rem;
        padding: 0.8rem;
        border-bottom: #fff dotted 2px;
        font-size: 0.9rem;
        font-weight: 500;
        color: #777;

        .ticket-part-button {
          max-width: 130px;
          flex-direction: row;
          align-items: center;
          align-content: center;
          flex-wrap: wrap;
          gap: 2%;
          justify-content: center;
          background-color: #f4cd46;
          border-radius: 0 10px 10px 0;
          font-size: 1rem;
          font-weight: 600;
          cursor: pointer;
          &:hover {
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
          }
          span {
            padding: 5px;
          }
        }

        .ticket-parts {
          width: 100%;
          height: 50%;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
          border: none !important;
          font-size: 16px;
          @media only screen and (max-width: 600px) {
            padding: 5px 2px;
          }
          p {
            margin: 0;
            @media only screen and (max-width: 400px) {
              font-size: 12px;
              width: auto;
            }
          }

          h6 {
            margin: 0;
            font-size: 19px;
            font-weight: 600;
            @media only screen and (max-width: 600px) {
              font-size: 14px;
            }
          }

          span {
            color: rgba(52, 52, 52, 0.746);
            margin-right: 5px;
            font-size: 14px;
            @media only screen and (max-width: 600px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
.no-bought-tickets-msg,
.no-searched-users {
  text-align: center;
  margin: 20px;
  font-weight: 600;
  color: #455cd9;
}
