.order-header {
  display: flex;
  gap: 10px;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  padding-bottom: 5px;
  border-bottom: 2px dotted rgb(170, 170, 170);
  margin-bottom: 20px;
  h4 {
    margin: 0;
  }
}

.order-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: min(96%, 800px);
  .order-display {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin: 0 auto;
    font-size: 1.2rem;
    padding: 0 0.8rem;
    border-radius: 15px;
    margin-bottom: 30px;
  }
  .flex-icons-container {
    width: fit-content;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0.8rem;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    svg {
      margin-bottom: 3px;
    }
  }
  .ticket-catcher {
    margin-bottom: 10%;
    .ticket-cather-header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px auto;
    }
  }
}
