.profile-form .mytickets-card .event-request-landscape {
  height: 100%;
  @media only screen and (max-width: 400px) {
    display: none;
  }
}
.verify-event-wrapper {
  padding: 10px;
  display: flex;
  align-items: center;

  .verify-event-wrapper-img {
    width: 70%;
    border-radius: 5px;
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  .verify-event-portrait-wrapper {
    margin-right: 10px;
    img {
      height: 192px;
      min-width: 120px;
    }
  }
  .sponsors-wrapper {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    flex-grow: 1;
    padding: 5px;

    h6 {
      margin: 0 auto;
      text-align: center;
    }

    ul {
      width: 100%;
      margin: 20px auto 0;

      padding: 0;
      height: 120px;
      overflow-y: auto;

      li {
        font-weight: 600;
        margin-bottom: 10px;
        text-align: center;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .line {
    width: 2px;
    background-color: rgba(255, 255, 255, 0.505);
    height: 180px;
    display: block;
    margin: 0 15px;
    @media only screen and (max-width: 600px) {
      margin: 0 6px;
    }
  }
  .description-wrapper {
    height: 180px;
    flex-grow: 1;
    overflow-y: auto;

    h6 {
      background-color: rgba(255, 255, 255, 0.508);
      width: fit-content;
      padding: 5px 15px;
      border-radius: 10px;
    }

    p {
      line-height: 21px;
      font-size: 15px;
      margin: 10px 10px 0 0;
      font-weight: 600;
      @media only screen and (max-width: 600px) {
        font-size: 13px;
        line-height: 17px;
      }
    }
  }
  .event-verifier-btns-wrapper {
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 170px;

    div {
      width: 40px;
      margin: 0 auto;
      background-color: rgba(255, 255, 255, 0.774);
      padding: 10px;
      border-radius: 7px;
      cursor: pointer;
      @media only screen and (max-width: 600px) {
        width: 35px;
      }
      &:hover {
        transform: scale(1.1);
      }

      img {
        width: 100%;
      }
    }
  }
  .additional-event-info {
    div {
      background-color: rgba(255, 255, 255, 0.507);
      border-radius: 10px;
      padding: 5px 2px;
      margin: 20px auto 0;

      &:first-child {
        margin-top: 0;
      }
    }

    p {
      text-align: center;
      margin: 5px 0;
      @media only screen and (max-width: 600px) {
        font-size: 13px;
      }
    }
  }
  h6 {
    text-align: center;
    margin: 5px 0;
    @media only screen and (max-width: 600px) {
      font-size: 15px;
    }
    @media only screen and (max-width: 350px) {
      font-size: 14px;
    }
  }
}
