.profile .profile-form .form .sponsor-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(250, 250, 250);
  max-height: 800px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 998;
  .modal-content {
    height: 500px;
    margin: 0 auto;
    min-width: 350px;
    .sponsors-wrapper {
      display: flex;
      padding: 15px;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      .sponsor-row {
        min-height: 50px;
        border-bottom: 1px dotted #b8bede;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          max-height: 42px;
          max-width: 80px;
          object-fit: cover;
        }
      }
    }
  }
  .modal-btn-close {
    font-size: 14px;
  }
}
