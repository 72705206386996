.reseller-requests-wrapper,
.add-reseller-event {
  .myevent-card {
    .info-main-card {
      background-color: rgba(217, 217, 223, 0.505);
      border-radius: 15px;
      margin: 10px 15px 0;
      display: flex;
      justify-content: space-between;
    }
    overflow: hidden;
    &:nth-child(odd) {
      .line {
        background-color: rgba(201, 204, 224, 0.505);
      }
    }

    &:nth-child(even) {
      .line {
        background-color: rgba(69, 91, 217, 0.507);
      }
    }
    .user {
      width: 7%;
    }

    .line {
      width: 2px;
      height: 80%;
    }

    .myevent-card-part-2 {
      width: 39%;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        margin: 0;
        font-size: 17px;
        font-weight: 600;
        text-align: center;

        @media only screen and (max-width: 450px) {
          font-size: 13px;
        }
      }
    }
    .myevent-card-part-3 {
      border-top-right-radius: 10px;
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .dropdown-arrow {
        height: 30px;
      }
      p {
        text-align: center;
        font-size: 12px;
        margin: 0 0 5px 0;
        font-weight: 600;

        @media only screen and (max-width: 460px) {
          display: none;
        }
      }
    }
  }

  .resellers-list-dropdown {
    display: flex;
    top: 100px;
    padding-right: 5px;
    p {
      margin: 0;
    }

    .reseller-dropdown-part {
      display: inline-block;
      border-radius: 10px;
    }

    .reseller-dropdown-part:nth-child(1) {
      width: 49.5%;
      padding: 15px;
      background-color: rgba(255, 255, 255, 0.578);
      margin: 10px;

      div {
        display: flex;
        margin-bottom: 15px;

        p:nth-child(1) {
          color: #455cd9;
          margin-right: 10px;
        }
      }

      div:last-child {
        margin-bottom: 0;
      }
    }
    .reseller-dropdown-part:nth-child(2) {
      width: 39.5%;
      margin: 10px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      div {
        min-height: 55px;
        text-align: center;
        width: 90%;
        margin: 0 auto;
        background-color: rgba(255, 255, 255, 0.578);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        p {
          font-size: 15px;
          @media only screen and (max-width: 500px) {
            font-size: 12px;
          }
        }

        p:nth-child(1) {
          color: #455cd9;

          @media only screen and (max-width: 500px) {
            font-size: 12px;
          }
        }
      }
    }
    .reseller-dropdown-part:nth-child(3) {
      width: 5.5%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin: 10px;
      padding: 5px 0;

      div {
        background-color: rgba(255, 255, 255, 0.578);
        width: 40px;
        height: 40px;
        border-radius: 10px;
        padding: 7px;
        cursor: pointer;

        @media only screen and (max-width: 500px) {
          width: 30px;
          height: 30px;
        }

        &:hover {
          img {
            transform: scale(1.15);
          }
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.add-reseller-event {
  .top-part {
    border-bottom: none;
    @media only screen and (max-width: 780px) {
      flex-direction: column;
    }

    .info {
      @media only screen and (max-width: 780px) {
        order: 2;
        width: 100%;
        max-width: 400px;
      }
    }
  }
}
