.add-reseller-event-bottom {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  @media only screen and (max-width: 780px) {
    flex-direction: column;
  }

  .add-reseller-card {
    display: flex;
    flex-direction: column;
    background-color: #f6f6f6;
    box-shadow: 0 0 10px #eee;
    outline: 1px solid #eee;
    border-radius: 10px;
    margin-bottom: 20px;
    .resellers-top-part-wrapper {
      display: flex;
      margin: 1rem;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .add-reseller-card-part-2 {
        font-size: 0.9rem;
        flex-direction: column;
        text-align: center;
        font-weight: 600;
        gap: 0.5rem;
        display: flex;
        padding: 8px;
        flex: 1;
        justify-content: space-between;
      }
      .reseller-switchers {
        display: flex;
        gap: 3rem;
        text-align: center;
        justify-content: space-around;
        flex: 1;
      }
    }
    .myevent-card-part-3 {
      border-top-right-radius: 0;
      padding-top: 4px;
      color: #fff;
      span {
        font-size: 0.9rem;
      }
    }

    .resellers-list-dropdown {
      flex-wrap: wrap;
      align-items: center;
      gap: 0.25rem;
      border-radius: 0 0 0.5rem 0.5rem;
      background-color: rgb(228, 230, 242);

      .add-reseller-dropdown-part {
        flex: 1;
        margin: 0.3rem;
        text-align: center;
        border-radius: 0.3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 210px;
        justify-content: center;
        gap: 0.5rem;
        padding: 0.3rem;
        background-color: rgb(251, 251, 251);
        .add-reseller-dropdown-title {
          font-size: 0.9rem;
          font-weight: 500;
        }
        input {
          text-align: center;
        }
      }
    }
  }
}
.bottom-part:nth-child(1) {
  width: 70%;

  @media only screen and (max-width: 780px) {
    width: 100%;
    margin: 0;
  }

  .user-manager-top {
    width: 100%;
    margin-bottom: 10px;

    div:nth-child(2) {
      .search-input-wrapper {
        width: 100%;
      }
    }
  }
}
.bottom-part:nth-child(2) {
  width: 28%;
  background-color: #fff;
  margin: 52px 0 0 auto;
  border-radius: 10px;
  box-shadow: 0 0 2px 0 (rgba(0, 0, 0, 0.252));
  height: fit-content;
  padding: 5px 0;

  @media only screen and (max-width: 780px) {
    width: 100%;
    margin: 0;
  }

  h6 {
    color: #455cd9;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }

  p {
    text-align: center;
    font-size: 13px;
    margin: 0 0 3px 0;
  }

  .line {
    width: 80%;
    margin: 10px auto;
    height: 2px;
    background-color: #7c8ce4;
  }

  .category {
    background-color: rgba(69, 91, 217, 0.108);
    width: 90%;
    margin: 0 auto;
    border-radius: 10px;
    padding: 5px;
    margin-bottom: 10px;
  }
  .category:last-child {
    margin-bottom: 0;
  }
}
