.user-manager-top {
  display: flex;
  width: 100%;

  div {
    padding: 5px 10px;
    display: flex;
    align-items: center;

    p {
      font-weight: 600;
      color: #455cd9;
      margin: 10px 0;
    }

    &:nth-child(1) {
      border-bottom: 2px solid #7c8ce4;
      width: 60%;
    }
    &:nth-child(2) {
      border-bottom: 2px solid #7c8ce4;
      width: 30%;
      margin-left: auto;
      padding: 5px 0;
    }

    .search-input-wrapper {
      border: none;
      background-color: #fff;
      height: 30px;
      width: 100%;
      border-radius: 10px;

      img {
        width: 20px;
        height: 20px;
      }

      input {
        outline: none;
        height: 100%;
        width: 100%;
        color: #343434;

        &::placeholder {
          font-size: 14px;
          color: #455cd9;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
}
.user-manager-bottom {
  overflow-y: auto;
  height: 340px;

  .user-manager-card {
    box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;

    @media only screen and (max-width: 400px) {
      flex-direction: column;
      align-items: center;
    }

    .card-part-half {
      width: 65%;
      display: flex;

      @media only screen and (max-width: 400px) {
        width: 100%;
      }

      .um-card-part {
        width: 100%;
      }

      &:nth-child(3) {
        width: 30%;

        @media only screen and (max-width: 600px) {
          width: 35%;
        }

        @media only screen and (max-width: 400px) {
          width: 65%;
        }
      }
    }

    @media only screen and (max-width: 490px) {
      padding: 10px 2px;
    }

    .user {
      width: 5%;
      margin-right: 5px;

      @media only screen and (max-width: 600px) {
        display: none;
      }
    }

    .role-selector {
      @media only screen and (max-width: 680px) {
        font-size: 13px;
        width: 100%;
        max-width: 100px;
      }
    }
    &:nth-child(odd) {
      background-color: #fff;

      .um-line {
        background-color: rgba(201, 204, 224, 0.5);
      }

      .um-icons-wrapper {
        div {
          background-color: rgba(69, 91, 217, 0.107);
        }
      }
      .role-selector {
        background-color: rgba(69, 91, 217, 0.107);
      }
    }
    &:nth-child(even) {
      background-color: rgba(69, 91, 217, 0.118);

      .um-line {
        background-color: rgba(255, 255, 255, 0.526);
      }

      .um-icons-wrapper {
        div {
          background-color: rgba(255, 255, 255, 0.856);
        }
      }
      .role-selector {
        background-color: rgba(255, 255, 255, 0.856);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .um-card-part {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-around;
      font-weight: 600;
      width: 32.5%;
      padding: 0 5px;
      text-align: center;

      @media only screen and (max-width: 600px) {
        width: 29.5%;
      }

      p {
        margin: 0;

        @media only screen and (max-width: 680px) {
          font-size: 13px;
        }
      }

      .is-verified,
      .set-user-role {
        display: flex;
        align-items: center;

        img {
          width: 20px;
          margin-left: 10px;
        }
      }

      .set-user-role {
        cursor: pointer;
      }
    }

    .um-middle-part {
      align-items: center;

      p {
        text-align: center;
      }
    }
    .um-last-part {
      width: 23%;
      align-items: center;

      @media only screen and (max-width: 600px) {
        width: 33%;
      }
    }

    .um-line {
      width: 2px;
      height: 80px;

      &:nth-child(1) {
        @media only screen and (max-width: 400px) {
          display: none;
        }
      }
      &:nth-child(3) {
        @media only screen and (max-width: 400px) {
          display: none;
        }
      }
    }
    .um-icons-wrapper {
      width: 35px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-left: 5px;

      div {
        width: 100%;
        max-width: 38px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        cursor: pointer;
        border-radius: 7px;

        &:nth-child(2) {
          margin-top: 5px;
        }

        img {
          width: 90%;
          height: 90%;
          cursor: pointer;
        }

        @media only screen and (max-width: 680px) {
          max-width: 28px;
        }
      }
    }
  }
}
