.draw-place-container {
  position: relative;

  h5 {
    text-align: center;
    color: #455cd9;
    margin: 30px auto;
    font-weight: 600;
  }

  .add-image-label {
    margin: 0 auto;
    width: fit-content;
    display: block;
    .plus-icon {
      display: block;
      width: 35px;
      margin: 0 auto;
      cursor: pointer;
      transition: transform 0.2s;

      &:hover {
        transform: translateY(-2px);
      }
    }
  }

  .buttons-wrapper {
    padding: 30px;
    display: flex;

    .active-link {
      transform: translateY(-3px);
      box-shadow: 0 5px 2px -2px gray;
    }

    a {
      text-decoration: none;
      background-color: #f4cd46;
      color: #343434;
      font-weight: 600;
      padding: 10px 30px;
      border-radius: 20px;
      margin-right: 20px;
      display: flex;
      width: fit-content;
      align-items: center;

      &:last-child {
        margin-right: 0;

        img {
          width: 20px;
        }
      }

      &:hover {
        transform: translateY(-2px);
      }

      .rectangle {
        width: 25px;
        height: 15px;
        border: 2px dashed rgba(65, 65, 65, 0.697);
        border-radius: 2px;
        display: inline-block;
        margin-left: 8px;
        pointer-events: none;
      }

      img {
        width: 25px;
        margin-left: 8px;
        pointer-events: none;
      }
    }
  }

  .img-container {
    width: 100%;
    background-color: #fff;
    padding: 50px 10px;

    img {
      margin: 0 auto;
    }

    svg {
      margin: 0 auto;
      display: block;
      g {
        rect,
        circle {
          transition: none;
        }
      }

      image {
        width: 100%;
      }
    }
  }

  .save-map {
    width: fit-content;
    display: flex;
    margin: 30px auto 0;
    text-decoration: none;
    background-color: #f4cd46;
    color: #343434;
    font-weight: 600;
    padding: 10px 30px;
    border-radius: 20px;
    align-items: center;

    &:hover {
      transform: translateY(-2px);
    }

    img {
      width: 20px;
      pointer-events: none;
      margin-left: 10px;
    }
  }

  .draw-modal {
    position: absolute;
    background-color: #fff;
    width: fit-content;
    border-radius: 10px;
    top: 30%;
    transform: translateY(-50%);
    right: 0;
    left: 0;
    margin: 0 auto;
    z-index: 9999;
    width: 100%;
    max-width: 400px;
    padding: 30px 10px;

    h6 {
      text-align: center;
      font-weight: 600;
    }

    .main-modal-heading {
      margin-bottom: 30px;
    }

    div {
      background-color: #d9def7;
      border-radius: 5px;
      padding: 0 0 10px 0;

      p {
        display: inline-block;
        margin: 0;
        text-align: left;
        width: fit-content;
        background-color: #c3c7de;
        margin-right: auto;
        border-top-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: 5px;
      }

      input {
        margin: 0 auto;
        display: block;
        width: 100%;
        max-width: 150px;
        height: 30px;
        text-align: center;
        font-size: 15px;
        border: none;
        outline: none;
        border-radius: 5px;

        &:focus {
          outline: none;
        }
      }

      h6 {
        color: #343434;
        font-size: 16px;
      }

      img {
        width: 25px;
        cursor: pointer;
        margin: 0 auto;
        display: block;
        margin-top: 30px;
      }

      .row-wrapper {
        height: 115px;
        overflow-y: auto;
      }
    }
    .seats-price-wrapper {
      margin-top: 30px;

      input {
        margin: 20px auto;
      }
    }

    .save-zone {
      display: block;
      text-decoration: none;
      width: fit-content;
      margin: 20px auto 0;
      background-color: #f4cd46;
      color: #343434;
      font-weight: 600;
      padding: 5px 30px;
      border-radius: 20px;

      &:hover {
        transform: translateY(-2px);
      }
    }
  }
}
