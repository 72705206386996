.hero {
  padding: 0 0 50px 0;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;

  .swiper-container-landing {
    padding-top: 4px;
  }
  .slider-img {
    pointer-events: none;
    object-fit: cover;
    height: 100%;
    border-radius: 0.5rem;
  }

  .slider-img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(69, 92, 217, 0.4);
    backdrop-filter: blur(5px);
    height: 100%;
    width: 100%;
    border-radius: 10px;
    transition: opacity 0.2s;
    opacity: 0;
  }
}

.swiper-slide-landing {
  width: fit-content;
  height: fit-content;
}
.slider-image-container,
.card-btns-wrapper {
  width: 100%;
  height: 330px;
  .links-container-hero {
    height: 100%;
    width: 100%;
    margin: 5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    opacity: 0;
    transition: opacity 0.2s, background-color 0.2s;

    .links-flex {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 1rem;
      width: min-content;
    }
    a {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.6rem;
      background-color: #f4cd46;
      text-align: center;
      padding: 6px 10px;
      color: #575757;
      border-radius: 50px;
      text-decoration: none;
      font-size: 1rem;
      font-weight: 600;
      &:hover {
        background-color: #dbb83f;
      }
    }
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    background-color: #f4cd46;
    text-align: center;
    padding: 6px 10px;
    color: #444;
    border-radius: 50px;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    &:hover {
      background-color: #dbb83f;
    }
  }
}
.main-search-container {
  background: linear-gradient(to bottom, #ffffff00 0%, #fbfbfb 20%, #fbfbfb 80%, #ffffff00 100%);
  padding: 1%;
  margin-top: 3%;

  .loader {
    width: 80px;
    margin: 0 auto;
  }

  .search-mini-nav {
    display: flex;
    flex-wrap: wrap;

    .search-nav-left {
      width: 100%;
      gap: 1rem;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      list-style: none;
      padding: 0 0 10px 0;
      margin-bottom: 5px;
      border-style: solid;
      border-image-slice: 1;
      border-width: 2px;
      border-left: 0;
      border-right: 0;
      border-top: 0;
      border-image-source: linear-gradient(to right, #35428698 0%, #2794a786 100%);
      .searchActive {
        background-color: #ffffff;
        color: #455cd9;
      }
      a {
        cursor: pointer;
        color: #666;
        text-decoration: none;
        padding: 6px 12px;
        border-radius: 0.2rem;
        transition: background-color 0.2s, color 0.2s;
        font-weight: 600;
        &:hover,
        .active-search-link {
          background-color: #ffffff;
          color: #455cd9;
        }
      }
    }
  }
}

.search-card-container {
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.15);
  padding-right: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 99%;

  &:nth-child(even) {
    background: #f3f3f3;
  }
  @media only screen and (max-width: 700px) {
    padding-right: 3px;
  }
  img {
    width: 235px;
    height: 135px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    @media only screen and (max-width: 700px) {
      width: 55%;
    }
    @media only screen and (max-width: 500px) {
      width: 40%;
    }
  }

  .search-card-content-wrapper {
    height: 135px;
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    @media only screen and (max-width: 700px) {
      flex-direction: column;

      @media only screen and (max-width: 700px) {
        width: 48%;
        justify-content: space-evenly;
      }
      .card-btns-wrapper {
        margin: 0;
        flex-direction: row;
        height: auto;

        a:nth-child(1) {
          margin-right: 5px;
        }
        @media only screen and (max-width: 700px) {
          a:nth-child(1) {
            margin: 0 0 5px 0;
          }
        }
      }

      .card-info-wrapper {
        h3,
        p {
          margin: 0;
          text-align: center;
          @media only screen and (max-width: 700px) {
            scale: 0.8;
            padding: 0;
            line-height: 18px;
          }
        }
        h3 {
          @media only screen and (max-width: 500px) {
            font-size: 18px;
          }
        }
      }
    }
  }
}
.search-cards-container {
  margin-top: 20px;
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 5px;
  @media only screen and (max-width: 700px) {
    padding-right: 0;
  }
  .card-info-wrapper {
    margin: 0 5px 0 10px;
    @media only screen and (max-width: 500px) {
      margin: 0;
    }

    @media only screen and (max-width: 450px) {
      // transform: scale(0.85);
    }

    h3 {
      font-size: 23px;
      font-weight: 600;
      @media only screen and (max-width: 500px) {
        margin: 0;
      }
    }

    p {
      color: rgba(52, 52, 52, 0.75);
      font-weight: 600;
      font-size: 15px;
      @media only screen and (max-width: 500px) {
        margin: 0;
      }

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}
.card-btns-wrapper {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: space-evenly;
  width: min-content;
  gap: 1%;
  a {
    text-decoration: none;
    position: relative;
    opacity: 1;
    top: 0;
    height: 2rem;
    width: 130px;
    font-weight: 600;
    font-size: 1rem;
  }

  @media only screen and (max-width: 600px) {
    gap: 3px;
    flex-direction: row;
    a {
      font-size: 0.9rem;
      width: min-content;
      margin: 0;
      @media only screen and (max-width: 360px) {
        svg {
          display: none;
        }
      }
    }
  }
}
.hot-events-wrapper {
  background-color: #f0f0f031;
  height: 563px;
  width: 100%;
  border-radius: 10px;
  padding: 30px 20px;
  text-align: center;
  overflow-y: auto;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.1);

  h3 {
    color: #343434;
    font-size: 25px;
    display: flex;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

  img {
    width: 25px;
    margin: 0 20px 2px 0;
  }

  @media only screen and (max-width: 991px) {
    margin: 40px auto 0 auto;
    width: 80%;
  }
  @media only screen and (max-width: 500px) {
    height: auto;
    width: 100%;
  }
}
.hot-events-row {
  width: 100%;
  margin: 0 auto;
  min-height: 150px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 15px;
  overflow: hidden;
  display: flex;
  padding: 10px 0;
  justify-content: space-evenly;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }

  &:last-child {
    margin-bottom: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    filter: blur(4px);
  }

  .hot-events-row-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    background-color: rgba(21, 21, 21, 0.2);
    z-index: 2;
  }

  .event-num {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(87, 107, 220, 0.7);
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #fff;
    font-weight: 600;
    padding: 10px;
    font-size: 20px;
  }

  h4 {
    z-index: 3;
    position: relative;
    text-align: center;
    color: rgba(255, 255, 255, 0.912);
    font-weight: 600;
    width: 100%;
    padding: 0 44px;
  }

  .event-datetime {
    z-index: 3;
    position: relative;
    background-color: rgba(255, 255, 255, 0.745);
    width: 60%;
    margin: 0 auto;
    border-radius: 5px;
    margin-top: 15px;
    padding: 5px;
    font-size: 14px;

    p {
      margin: 0;
      font-weight: 600;

      &:first-child {
        margin-bottom: 5px;
      }

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}
