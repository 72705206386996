.choose-concert {
  margin-left: 10px;
  width: min(300px, 85%);

  h6 {
    padding: 0 !important;
  }
}

.event-search {
  position: relative; // Ensure relative positioning for absolute child elements
  input {
    position: relative;
    width: 100%;
  }
  ul {
    position: absolute;
    border-radius: 0 0 0.3rem 0.3rem;
    z-index: 990;
    background-color: #1414148e;
    padding: 0;
    overflow: auto;
    max-height: 170px;
    width: 300px;
    backdrop-filter: blur(3px);
    display: none; // Default hidden
  }
  // When input is focused, display the dropdown
  input:focus + ul {
    display: block;
  }
  ul li {
    color: white;
    z-index: 999;
    text-align: center;
    list-style: none;
    cursor: pointer;
    display: block;
    padding: 6px 0;

    &:nth-child(2n) {
      background-color: rgba(35, 36, 63, 0.055);
    }
    &:hover {
      background-color: #f4cd46;
      color: #3a3a3a;
    }
  }
}

.top {
  height: 150px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  margin: 10px auto 20px;
  background-color: #5d627e2a;

  border-radius: 0.5rem;
  .info {
    line-height: 20px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 10px;
    align-items: center;
    text-align: center;
  }
}

.img-admin {
  height: 100%;
  object-fit: cover;
  width: 50%;
  border-radius: 0 10px 10px 0;
}

.print-history-wrapper {
  .print {
    line-height: 18px;
    display: flex;
    margin-bottom: 10px;
    padding: 4px;
    background-color: #8783961a;
    border-radius: 10px;
    .gen-info {
      min-width: 40%;
      max-width: 40%;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      padding: 5px;
      border-radius: 10px;
      margin-right: 15px;
    }
    .zones-wrapper {
      overflow: auto;
      flex-grow: 1;
      border-radius: 10px;
      .zones {
        margin: 0 0 0 auto;
        width: fit-content;
        display: flex;
        gap: 5px;

        align-items: center;

        .zone-card {
          background: #ffffff;
          padding: 5px 15px;
          border-radius: 10px;
          min-width: fit-content;

          text-align: center;
          .zone-name {
            font-weight: bold;
          }
        }
      }
    }
  }
}
.form-wrapper {
  display: flex;
  margin-top: 20px;
  justify-content: space-around;
  flex-wrap: wrap;

  form {
    margin-bottom: 20px;
    display: flex;

    width: max(40%, 400px);
    align-items: center;
    justify-content: center;

    .ticket-finder-input {
      width: 200px;
      height: 32px;
      margin: 5px 0 5px 20px;
      text-align: center;
      border-radius: 20px 0 0 20px;
    }
    .ticket-finder {
      margin: 0;
      flex-grow: 1;
      margin-bottom: 5px;
      height: 32px;
      align-self: flex-end;
      border-radius: 0 20px 20px 0;
    }
  }
}

.ticket {
  margin-bottom: 15px;
  background: #5d627e2a;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;

  .ticket-validator {
    margin: 0 !important;
    height: 100%;
    width: 90px;
  }
  img {
    margin-left: 5px;
    height: 20px;
    width: 20px;
  }
}
