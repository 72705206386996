.thank-you-container,
.failed-container {
  height: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  img {
    width: 40%;
  }

  h3 {
    font-size: 25px;
    font-weight: 600;
  }

  a {
    color: #576cdc;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
.failed-container {
  margin-top: 30px;
  img {
    margin-bottom: 30px;
    width: 300px;
    @media only screen and (max-width: 800px) {
      width: 180px;
    }
  }
}
