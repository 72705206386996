.this-week {
  padding: 3%;
  @media (max-width: 1200px) {
    padding: 1%;
  }
  .swiper-slide-this-weak {
    width: min(450px, 95%);
  }
  h3 {
    text-align: center;
    margin-bottom: 5%;
    font-weight: 700;
    margin-top: 50px;
  }

  .slider-card {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    position: relative;
    border-radius: 10px;
    text-decoration: none;

    p {
      text-align: center;
      margin-bottom: 0;
      @media (max-width: 450px) {
        font-size: 14px;
      }
    }
    img {
      border-radius: 10px;
      width: 100%;
      pointer-events: none;
    }

    .date {
      @media (max-width: 768px) {
        font-size: 90%;
      }

      &::first-letter {
        text-transform: uppercase;
      }
    }
    .location {
      color: #565656;
      @media (max-width: 768px) {
        font-size: 80%;
      }
    }
  }
}
