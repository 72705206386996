.single-page-container {
  margin-top: -13px;
  // background: linear-gradient(0deg, rgba(34, 3, 173, 0.05) 0%, rgb(255, 255, 255) 100%);
  .share {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    span {
      font-weight: 700;
    }
  }
  .share-buttons {
    color: #424242;
    width: fit-content;
    background-color: white;
    padding: 4px 8px;
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    a {
      color: #636363;
      transition: all 0.3s;
      &:hover {
        translate: 0 -3px;
        scale: 1.15;
      }
    }
  }

  .modal-seats {
    display: block;
    height: fit-content;
    position: relative;
    border-radius: 10px;
    overflow: auto;
    max-height: 350px;
    background-color: #fff;
    @media only screen and (max-width: 650px) {
      max-height: 200px;
    }
    .sold-empty {
      margin-bottom: 50px;
    }
    .zone-info {
      border-radius: 5px;
      position: fixed;
      z-index: 10;
      background: linear-gradient(150deg, rgb(213, 213, 213, 0.8) 0%, rgb(255, 255, 255, 0.6) 100%);
      padding: 2px 10px;
      font-size: 0.9rem;
      font-weight: 700;
    }

    h6 {
      margin-bottom: 30px;
    }

    a {
      text-decoration: none;
      width: fit-content;
      background-color: #f4cd46;
      color: #343434;
      margin: 0 auto;
      display: block;
      padding: 5px 30px;
      border-radius: 50px;
    }
  }
  .svg-scroll-container {
    width: fit-content;
    display: flex;
    justify-content: center; /* Center SVG horizontally */
    background-color: #ffffff;
    padding: 10px;
    min-width: 100%;
    min-height: 100%;
  }

  .single-page-top {
    position: relative;
    height: 250px;

    .single-page-icons-wrapper {
      position: absolute;
      top: 29px;
      right: 21px;
      z-index: 1;
      width: 110px;

      div {
        width: 45px;
        height: 45px;
        padding: 6px;
        display: inline-block;
        background-color: rgba(255, 255, 255, 0.208);
        border-radius: 10px;
        cursor: pointer;

        &:nth-child(2) {
          margin-left: 10px;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @media only screen and (max-width: 800px) {
        height: 180px;
      }
    }

    .cover-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(6, 6, 6, 0.1);
      backdrop-filter: blur(12.5px);
    }
  }

  .single-main-card {
    background-color: #eceefb;
    max-width: 1200px;

    margin-top: -100px;
    z-index: 10;
    position: relative;
    border-radius: 10px;
    padding: 20px;

    @media only screen and (max-width: 650px) {
      max-width: 95%;
    }
    h3 {
      font-weight: 600;
      font-size: 26px;
    }

    .card-main-info {
      background-color: #ffffff;
      width: fit-content;
      padding: 8px 12px;
      border-radius: 7px;
      color: rgba(52, 52, 52, 0.75);
      font-weight: 600;
      margin-top: 14px;
      @media only screen and (max-width: 650px) {
        font-size: 12px;
      }
    }

    .line {
      width: 100%;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.81);
      margin: 22px 0;
    }
    .card-other-info {
      width: 95%;
      font-size: 15px;
      color: rgba(52, 52, 52, 0.75);
      @media only screen and (max-width: 991px) {
        width: 100%;
      }
      @media only screen and (max-width: 650px) {
        font-size: 13px;
      }
    }

    .single-page-cover {
      width: 100%;

      border-radius: 10px;
      @media only screen and (max-width: 991px) {
        margin: 0 auto;
      }
    }
    .single-page-wrapper {
      align-items: center;
      overflow: hidden;
      border-radius: 10px;
      overflow-x: auto;
      justify-content: center;
      @media only screen and (max-width: 991px) {
        margin: auto;
        padding: 0;
      }
    }
    .sinkle-page-row {
      height: 100%;
    }
  }

  .secondary-card-single {
    max-width: 1200px;
    margin: 50px auto 0 auto;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    display: flex;
    align-items: center;
    background-color: #fff;
    padding-right: 48px;
    @media only screen and (max-width: 991px) {
      margin: 50px auto;
      padding-right: 10px;
    }
    @media only screen and (max-width: 650px) {
      width: 95%;
    }
    @media only screen and (max-width: 500px) {
      margin: 30px auto;
      min-width: 90%;
      padding: 0;
      justify-content: space-between;
    }

    img {
      height: 140px;
      border-radius: 7px 0px 0px 7px;
      @media only screen and (max-width: 450px) {
        width: 70px;
        height: 120px;
      }
    }

    div {
      text-align: center;
      flex-grow: 1;
      padding: 4px;
      @media only screen and (max-width: 450px) {
        margin-top: 10px;
        width: 100%;
      }
      h3 {
        font-weight: 600;
        font-size: 22px;
        @media only screen and (max-width: 500px) {
          font-size: 18px;
        }
      }

      p {
        font-size: 15px;
        color: rgba(52, 52, 52, 0.75);
        text-align: center;
        margin: 0 auto;
        @media only screen and (max-width: 500px) {
          font-size: 12px;
        }
      }
    }
    .right {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
      @media only screen and (max-width: 650px) {
        grid-template-columns: 1fr;
        min-height: 100px;
      }
      @media only screen and (max-width: 500px) {
        transform: scale(0.95);
      }

      .single-date {
        background-color: #f4f4f4;
        width: fit-content;
        padding: 2% 5%;
        margin: 0 5%;
        border-radius: 7px;
        justify-self: self-start;
        align-self: center;
        @media only screen and (max-width: 650px) {
          justify-self: center;
        }
        @media only screen and (max-width: 500px) {
          justify-self: flex-end;
        }
      }

      a {
        align-self: center;
        min-width: 150px;

        justify-self: self-end;
        background-color: #f4cd46;
        color: #414141;
        font-weight: bold;
        text-decoration: none;
        padding: 2% 10%;
        border-radius: 50px;
        @media only screen and (max-width: 650px) {
          justify-self: center;
          min-width: 100px;
        }
        @media only screen and (max-width: 500px) {
        }
      }
    }
  }

  .single-page-loader {
    width: 240px;
    height: 40px;
    border-radius: 10px;
    background: linear-gradient(#576cdc 50%, #0000 0), linear-gradient(#0000 50%, #576cdc 0),
      linear-gradient(#576cdc 50%, #0000 0), linear-gradient(#0000 50%, #f4cd46 0),
      linear-gradient(#f4cd46 50%, #0000 0), linear-gradient(#0000 50%, #f4cd46 0) #e4e4ed;
    background-size: calc(100% / 6 + 2px) 200%;
    background-repeat: no-repeat;
    animation: p2 2s infinite;
    margin: 50px auto;
  }
  @keyframes p2 {
    0% {
      background-position: calc(0 * 100% / 5) 100%, calc(1 * 100% / 5) 0%, calc(2 * 100% / 5) 100%,
        calc(3 * 100% / 5) 0%, calc(4 * 100% / 5) 100%, calc(5 * 100% / 5) 0%;
    }
    16.67% {
      background-position: calc(0 * 100% / 5) 0%, calc(1 * 100% / 5) 0%, calc(2 * 100% / 5) 100%,
        calc(3 * 100% / 5) 0%, calc(4 * 100% / 5) 100%, calc(5 * 100% / 5) 0%;
    }
    33.33% {
      background-position: calc(0 * 100% / 5) 0%, calc(1 * 100% / 5) 100%, calc(2 * 100% / 5) 100%,
        calc(3 * 100% / 5) 0%, calc(4 * 100% / 5) 100%, calc(5 * 100% / 5) 0%;
    }
    50% {
      background-position: calc(0 * 100% / 5) 0%, calc(1 * 100% / 5) 100%, calc(2 * 100% / 5) 0%,
        calc(3 * 100% / 5) 0%, calc(4 * 100% / 5) 100%, calc(5 * 100% / 5) 0%;
    }
    66.67% {
      background-position: calc(0 * 100% / 5) 0%, calc(1 * 100% / 5) 100%, calc(2 * 100% / 5) 0%,
        calc(3 * 100% / 5) 100%, calc(4 * 100% / 5) 100%, calc(5 * 100% / 5) 0%;
    }
    83.33% {
      background-position: calc(0 * 100% / 5) 0%, calc(1 * 100% / 5) 100%, calc(2 * 100% / 5) 0%,
        calc(3 * 100% / 5) 100%, calc(4 * 100% / 5) 0%, calc(5 * 100% / 5) 0%;
    }
    100% {
      background-position: calc(0 * 100% / 5) 0%, calc(1 * 100% / 5) 100%, calc(2 * 100% / 5) 0%,
        calc(3 * 100% / 5) 100%, calc(4 * 100% / 5) 0%, calc(5 * 100% / 5) 100%;
    }
  }
}
