.live-entrance {
  width: 100%;
  margin: 0 auto;

  .wrapper-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }

  th,
  td {
    padding: 8px;
    text-align: left;
  }

  th {
    background-color: #e7e7e7;
    border-radius: 0.2rem;
  }
  tr {
    border-bottom: 1px dashed #ffffff;
  }

  .modal {
    position: fixed; // Fixed position to cover the entire screen
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // Semi-transparent background
    display: flex; // Flexbox to center content
    align-items: center; // Vertically center
    justify-content: center; // Horizontally center
    z-index: 1000; // Ensure it's above other content

    .modal-content {
      background-color: #fff; // White background for content
      padding: 20px; // Padding around the content
      border-radius: 8px; // Rounded corners
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); // Soft shadow for depth
      max-width: 600px; // Limit the width of the modal
      width: 90%; // Responsive width
      text-align: left; // Align text to the left
      position: relative;
      .button-wrapper {
        position: absolute;
        right: 10px;
        top: 10px;
        display: flex; // Flexbox for spacing
        gap: 10px;
        button {
          margin-top: 10px; // Space above button
          padding: 10px 15px; // Padding for the button
          border: none; // Remove default border
          border-radius: 5px; // Rounded corners for button
          background-color: #007bff; // Primary color for button
          color: #fff; // White text
          cursor: pointer; // Pointer cursor on hover
          transition: background-color 0.3s; // Transition for hover effect

          &:hover {
            background-color: #0056b3; // Darker shade on hover
          }
        }
      }
      p {
        margin: 10px 0; // Margin for paragraphs
      }

      ul {
        list-style-type: none; // Remove bullets from list
        padding: 0; // Remove padding
        margin: 10px 0; // Margin around the list

        li {
          margin-bottom: 5px; // Space between list items
        }
      }
    }
  }

  .entrance-total-card {
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    .total-card-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }

  .entrance-category-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;

    .entrance-category-card {
      width: 186px;
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 10px;
      border-radius: 8px;
      box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.1);
      &:last-child {
        border-right: none;
      }
    }
  }
  .progress-bar-container {
    height: 10px; /* Height of the progress bar */
    background-color: #e0e0e0; /* Background color of the container */
    border-radius: 5px; /* Rounded corners */
    overflow: hidden; /* To ensure the progress bar doesn't overflow */
    width: 95%;
    margin: 0 auto;
  }

  .progress-bar {
    border-radius: 5px;
    height: 100%; /* Full height of the container */
    background: linear-gradient(to right, #5effb4, #649dff); /* Gradient background for the bar */
    position: relative; /* Position relative for absolute positioning of the fill */
  }

  .progress-fill {
    right: 0;
    position: absolute;
    height: 100%; /* Full height of the container */
    background-color: rgba(223, 223, 223, 0.85); /* Optional: semi-transparent overlay */
  }
}
