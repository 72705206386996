.Footer {
  .footer-logo {
    text-decoration: none;
    font-weight: 600;
    color: #686868;
  }

  // Sponsor Bar
  .sponsor-bar {
    position: relative;
  }

  .sponsor-scroll:before,
  .sponsor-scroll:after {
    position: absolute;
    top: 0;
    width: 20%;
    height: 100%;
    content: "";
    z-index: 2;
  }

  .sponsor-scroll:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff);
  }

  .sponsor-scroll:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff);
  }

  .sponsor-scroll {
    padding: 2% 0;
    overflow: hidden;
    .logos {
      height: 50px;
      white-space: nowrap;
      position: relative;
      width: fit-content;
    }

    .logos:hover .logos-slide {
      animation-play-state: paused;
    }

    .RED-SCREN {
      height: 50px;
    }
    .logos-slide {
      display: flex;

      animation: 15s slide infinite linear;

      @media only screen and (max-width: 600px) {
        animation-duration: 5s;
      }
    }

    .main-part {
      width: 150vw;
    }

    .second-part {
      width: 150vw;
    }

    .main-part,
    .second-part {
      display: flex;
      justify-content: space-evenly;
      gap: 1px;
    }

    .logos-slide img {
      max-height: 50px;
      max-width: 100px;
      object-fit: contain;
      filter: grayscale(100%);
    }

    @keyframes slide {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-150vw);
      }
    }
  }

  .info-panel {
    margin: 20px;
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }
    .logo {
      align-self: center;
      justify-self: center;
    }
    ul {
      list-style: none;
      margin-left: 0.5rem;
      padding: 0;
      font-weight: 500;
      li {
        a {
          text-decoration: none;
          color: #343434;
        }
        &:hover {
          transform: translateY(-3px);
        }
      }
    }
    .social-icons {
      grid-column: 1 / -1;
      display: flex;
      flex-grow: 1;
      margin: 0 auto;
      width: 90%;
      align-items: center;
      gap: 5%;
      justify-content: center;
    }
  }
  .online-payment {
    background: linear-gradient(
      0deg,
      rgb(72, 71, 82, 0.5) 0%,
      rgb(173, 173, 173, 0.5) 66%,
      rgb(255, 255, 255, 0.5) 100%
    );
    @media (max-width: 500px) {
      background: linear-gradient(
        0deg,
        rgb(72, 71, 82, 0.7) 0%,
        rgb(173, 173, 173, 0.7) 80%,
        rgb(255, 255, 255, 0.5) 100%
      );
    }
    .payment-wrapper {
      .cards {
        padding: 20px;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        display: flex;
        gap: 20px;
        .card {
          width: 80px;
          border: none;
          background: transparent;
        }
        .monri {
          width: 250px;
          background: rgba(255, 255, 255, 0.444);
        }
      }
      .master {
        width: 95px;
      }
    }
  }
}
