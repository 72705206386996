.global-loader {
  color: white;
  font-size: 26px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  z-index: 9999;
  display: flex;
  gap: 2%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  button {
    right: 5%;
    top: 5%;
    padding: 2px 20px;
    background-color: #fff;
    font-size: 18px;
    border-radius: 10px;
    box-shadow: 0 0 10px -2px rgb(255, 255, 255);
    position: absolute;
    border: none;
  }
  .wrapper {
    display: flex;
    width: min(85%, 800px);
    gap: 10%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .big-flex {
      width: 100%;
      display: flex;
      gap: 10%;
      justify-content: center;
      align-items: center;
      .global-loader-text {
        font-size: 1.1rem;
      }
      .loader-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10%;
      }
    }

    .check-email {
      font-size: 20px;
      a {
        color: #8496ff;
      }
    }
  }
}
