.payment-request-table {
  width: 100%;

  .heading {
    padding: 0.3rem;
  }
  .tab-header {
    margin-bottom: 1rem;
  }
  .filter-wrapper {
    width: 100%;

    overflow: auto;
    margin: 0 auto 1rem;
    padding: 0.2rem 1rem;
    border-radius: 0.3rem;
    background-color: #f5f5f5;
  }
  .filters {
    min-width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-bottom: 0.5rem;
    gap: 0.5rem;
    .input-field-custom {
      width: 165px;
    }
  }
  .action-buttons-wrapper {
    display: flex;
    gap: 0.5rem;
    justify-content: end;
    align-items: center;
    margin-left: auto;
    flex-wrap: wrap;
  }

  .table-wrapper {
    border: 1px solid #eee;
    border-radius: 0.5rem;
    width: 100%;
    overflow: auto;
    table {
      width: 100%;
      min-width: fit-content;
      thead {
        font-size: 0.9rem;

        tr {
          background-color: rgb(244, 244, 244);
          padding: 0.3rem 0.6rem;

          border-radius: 0.3rem;

          th {
            color: #555;
            min-width: fit-content;
            font-weight: 600;
            padding: 0.5rem;
            text-wrap: nowrap;
            border-right: 1px solid #fff;
          }
        }
      }
      tbody {
        font-size: 0.85rem;
        color: #717171;
        font-weight: 500;
        tr {
          td {
            text-wrap: nowrap;
            padding: 0.3rem 0.6rem;
            border-bottom: 1px solid #eee;
          }
        }
        .actions {
          display: flex;
          justify-content: center;
        }
        .refund-icon {
          cursor: pointer;
          &:hover {
            scale: 1.1;
          }
        }
      }
    }
  }
  .table-pagination {
    margin: 0 auto;
    padding: 0.6rem 1rem;
    display: flex;
    justify-content: center;
    gap: 0.6rem;
    align-items: center;

    .profile-btn {
      padding: 0 0.6rem;
    }
    .pagination-dots {
      text-wrap: nowrap;
    }
  }
}

.text-center {
  text-align: center;
}

.aling-self-end {
  margin-left: auto;
  align-self: flex-end;
}

.refund-display-wrapper {
  .header {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
  }
  .tickets-info {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    .tickets {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      .ticket-card {
        min-width: 110px;
        border: 1px solid #ddd;
        padding: 1.2rem 0.6rem;
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.6rem;
      }
    }
    .ticket-number-text {
      font-family: monospace;
      font-weight: 600;
      font-size: 0.9rem;
    }
  }
  .refund-form {
    .refund-form-wrapper {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      gap: 0.6rem;
      margin-bottom: 1rem;
    }
  }
}
.dotted-border-bottom {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px dotted #ddd;
}
