.add-hall-container {
  .row {
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;

    .event-input {
      width: 44%;
      position: relative;
    }
    #info-icon {
      width: 20px;
      margin-left: auto;
      padding: 0;
    }

    h6 {
      color: #455cd9;
      font-size: 16px;
      font-weight: 600;
      padding: 0;
      margin-top: 30px;
    }
  }
  .place-input {
    position: relative;
    z-index: 1;
    .dropdown {
      position: absolute;
      z-index: 2;
      cursor: pointer;
      pointer-events: all;
      background-color: #fff;
      padding: 7px;
      border-radius: 10px;
      max-height: 200px;
      margin-left: auto;
      right: 0;
      top: 90%;
      overflow: auto;
      width: 44%;
      right: 0;
      .dropdown-item {
        margin-bottom: 4px;
        opacity: 0.8;
      }
    }
  }
  .hall-categories-container {
    padding: 0;

    .row {
      padding: 5px;
      width: 100%;
      margin: 0 auto;
      gap: 0;
      background-color: #fff;
      border-radius: 10px;
      margin-bottom: 10px;

      .event-input {
        background-color: rgba(69, 91, 217, 0.108);
      }

      .col-lg-6 {
        display: flex;
        flex-direction: column;
        width: 50%;
        input {
          text-align: center;
          width: 90%;
        }
      }

      .add-hall-right-col {
        border-left: 2px solid rgba(69, 91, 217, 0.505);

        .event-input {
          text-align: center;
          margin-left: auto;
        }
      }
    }
  }
  .add-category-icon {
    width: 30px;
    cursor: pointer;
    margin: 20px auto 0 auto;
    display: block;
  }

  form {
    .add-hall-btn {
      margin: 36px auto 20px auto;
      display: block;
    }
  }
}
