.check-reseller-tickets {
  .central-part {
    flex: 1;
    height: 100%;
    padding: 2%;
    gap: 1rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: 800px) {
      flex-direction: column;
    }
  }
  .resseller-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .reseller-event-image {
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  .dropdown-arrow {
    width: 46%;
    height: auto;
    cursor: pointer;
  }

  .dropdown-arrow-wrapper {
    width: 5%;
    height: 100%;
    background-color: rgba(69, 91, 217, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 10px;
    cursor: pointer;
  }

  p {
    font-weight: 500;
  }
  .reseller-info-tickets-totals {
    flex: 1;
    padding: 0 1%;
    display: flex;
    margin-left: auto;
    flex-wrap: wrap;
    max-width: 1100px;
    gap: 0.5rem;
    justify-content: space-between;
  }

  .profile-concert-wrapper {
    .check-ticket-container {
      width: 100%;
      overflow-x: auto;
      color: #777;
    }
    .check-ticket-manager {
      display: flex;
      width: 100%;
      padding: 4px;
      gap: 1rem;
      min-width: fit-content;
      justify-content: center;
      align-items: center;
      overflow-x: auto;
      overflow-y: hidden;

      .ticket-check-part {
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgb(200, 200, 200);
        border-radius: 1rem;

        .plus-button,
        .minus-button {
          cursor: pointer;
          &:hover {
            transform: scale(1.1);
          }
          &.disabled {
            opacity: 0.4;
            cursor: not-allowed;
            pointer-events: none;
          }
        }
        .ticket-check {
          display: flex;
          padding: 12px;
          flex-direction: column;
          gap: 0.5rem;
          align-items: center;
          text-align: center;
        }
      }
    }
  }

  .approve-ticket-sale {
    display: flex;
    width: 95%;
    margin: 5px auto 0;
    background-color: #fff;
    padding: 0 5px;
    height: 30px;
    border-radius: 10px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25%;
      border-right: 1px solid rgba(69, 91, 217, 0.305);
      height: 100%;

      p,
      span,
      a {
        margin: 0;
        font-size: 14px;
        font-weight: 600;
        text-align: center;

        @media only screen and (max-width: 500px) {
          font-size: 11px;
        }
      }

      span {
        color: rgb(52, 52, 52, 0.75);
      }

      a,
      .price {
        margin-left: auto;
        margin-right: 5px;
        min-width: 9%;
      }
      img {
        margin: 0 auto;
      }

      a {
        text-decoration: none;
        background-color: #f4cd46;
        color: #343434;
        padding: 4px 5px;
        border-radius: 10px;
        font-size: 12px;
        cursor: pointer;
      }

      img {
        width: 10%;
        height: auto;
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}
.reseller-status-wrapper {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  font-weight: 600;
  gap: 0.5rem;

  span {
    display: flex;
    align-items: center;
    gap: 0.4rem;
    svg {
      margin-left: 6px;
    }
  }
}
