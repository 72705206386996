/* Regular */
@font-face {
  font-family: "Open Sans";
  src: url("/public/static/fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Bold */
@font-face {
  font-family: "Open Sans";
  src: url("/public/static/fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Italic */
@font-face {
  font-family: "Open Sans";
  src: url("/public/static/fonts/OpenSans-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

/* Bold Italic */
@font-face {
  font-family: "Open Sans";
  src: url("/public/static/fonts/OpenSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

/* Light */
@font-face {
  font-family: "Open Sans";
  src: url("/public/static/fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

/* Light Italic */
@font-face {
  font-family: "Open Sans";
  src: url("/public/static/fonts/OpenSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

/* SemiBold */
@font-face {
  font-family: "Open Sans";
  src: url("/public/static/fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* SemiBold Italic */
@font-face {
  font-family: "Open Sans";
  src: url("/public/static/fonts/OpenSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

/* ExtraBold */
@font-face {
  font-family: "Open Sans";
  src: url("/public/static/fonts/OpenSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

/* ExtraBold Italic */
@font-face {
  font-family: "Open Sans";
  src: url("/public/static/fonts/OpenSans-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

/* Medium */
@font-face {
  font-family: "Open Sans";
  src: url("/public/static/fonts/OpenSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* Medium Italic */
@font-face {
  font-family: "Open Sans";
  src: url("/public/static/fonts/OpenSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
