.request-resseler-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .reseller-request-msg {
    color: #455cd9;
    margin-bottom: auto;
    font-weight: 600;
  }

  .reseller-request-form {
    background-color: rgba(69, 91, 217, 0.108);
    padding: 25px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    p {
      text-align: center;
      font-size: 13px;
      line-height: 20px;
    }
  }
}
